import revive_payload_client_4sVQNw7RlN from "/usr/src/nuxt-app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import router_jmwsqit4Rs from "/usr/src/nuxt-app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import siteConfig_Eeyr9ytLpT from "/usr/src/nuxt-app/node_modules/nuxt-site-config/dist/runtime/plugins/siteConfig.mjs";
import plugin_vue3_A0OWXRrUgq from "/usr/src/nuxt-app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/usr/src/nuxt-app/.nuxt/components.plugin.mjs";
import unhead_KgADcZ0jPj from "/usr/src/nuxt-app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import prefetch_client_5tzzN0oIVL from "/usr/src/nuxt-app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import motion_fmZWau4t0U from "/usr/src/nuxt-app/node_modules/@vueuse/motion/dist/runtime/templates/motion.mjs";
import plugin_8SbxDRbG6Y from "/usr/src/nuxt-app/node_modules/dayjs-nuxt/dist/runtime/plugin.mjs";
import composition_sLxaNGmlSL from "/usr/src/nuxt-app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_yfWm7jX06p from "/usr/src/nuxt-app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_UciE0i6zes from "/usr/src/nuxt-app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import api_0otAtqq586 from "/usr/src/nuxt-app/src/plugins/api.ts";
import fontawesome_KQNRGomcw2 from "/usr/src/nuxt-app/src/plugins/fontawesome.js";
import gsap_client_KE7tAZ0iCh from "/usr/src/nuxt-app/src/plugins/gsap.client.js";
import gtm_client_d6yxwQeAO7 from "/usr/src/nuxt-app/src/plugins/gtm.client.js";
import rdstation_client_3BveH34AVn from "/usr/src/nuxt-app/src/plugins/rdstation.client.js";
import vue_autocounter_D3dE7f2KTE from "/usr/src/nuxt-app/src/plugins/vue-autocounter.js";
import vue_scrollto_QkFLDCaMBa from "/usr/src/nuxt-app/src/plugins/vue-scrollto.ts";
import vueAwesomePaginate_SGc96wJq0G from "/usr/src/nuxt-app/src/plugins/vueAwesomePaginate.ts";
import vuetify_QwmrB3LYDa from "/usr/src/nuxt-app/src/plugins/vuetify.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  router_jmwsqit4Rs,
  siteConfig_Eeyr9ytLpT,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  unhead_KgADcZ0jPj,
  prefetch_client_5tzzN0oIVL,
  motion_fmZWau4t0U,
  plugin_8SbxDRbG6Y,
  composition_sLxaNGmlSL,
  i18n_yfWm7jX06p,
  chunk_reload_client_UciE0i6zes,
  api_0otAtqq586,
  fontawesome_KQNRGomcw2,
  gsap_client_KE7tAZ0iCh,
  gtm_client_d6yxwQeAO7,
  rdstation_client_3BveH34AVn,
  vue_autocounter_D3dE7f2KTE,
  vue_scrollto_QkFLDCaMBa,
  vueAwesomePaginate_SGc96wJq0G,
  vuetify_QwmrB3LYDa
]